<template>
  <button type="button" class="inline-flex brdr-none" @click="goBack" data-testid="returnButton">
    <i class="arrow left" />
  </button>
</template>

<script>
import ReturnIcon from '@vue-storefront/core/compatibility/components/blocks/Header/ReturnIcon'

export default {
  mixins: [ReturnIcon]
}
</script>

<style scoped>
.arrow {
  border: solid #191919;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 4px;
  right: 20px;
  bottom: 22px;
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
</style>
